<template>
  <div class="container bg">
    <div class="navBox dfc al-c">
      <el-page-header @back="goBack" content="声音克隆"> </el-page-header>
      <div class="dfc al-c">
        <div>
          声音克隆次数 ：<span class="fb fs16" style="color: #015de7">{{ info.sound_copy }}</span> 次
        </div>
        <!--<div>
          声音克隆数量 ：<span class="fb fs16" style="color: #015de7">{{ info.calculations == 2 ? info.high_sound_copy : info.sound_copy }}</span> 次
        </div><div class="timeBox"><i class="icon1 el-icon-mic"></i> 声音克隆次数: {{ info.sound_copy }} 次</div>-->
        <div class="listIcon ml20 mr10" style="cursor: pointer" @click="getList">
          <i class="el-icon-notebook-2"></i>
        </div>
      </div>
    </div>
    <div class="pt20">
      <el-row style="">
        <el-col :span="16" :offset="4">
          <div class="box">
            <div class="top">
              <div>一次录音，创造无限可能</div>
              <div class="mt10 fs18">提交 2 分钟录制音频，复刻真人音色、音律，克隆成功后可随意切换多国语言输出</div>
            </div>
            <!-- 
            <div class="mt20 dfc" style="justify-content: flex-end">
              <div style="color: #f56c6c" class="mt30 fs18">当前剩余声音克隆 {{ info.calculations == 2 ? info.high_sound_copy : info.sound_copy }} 次</div>
              <div class="listIcon ml450 mr10" style="cursor: pointer" @click="getList">
                <i class="el-icon-notebook-2"></i>
              </div>
            </div> -->
            <div class="info">
              <div class="dfc mt20">
                <div class="label">声音名称：</div>
                <el-input v-model="audioName" placeholder="请输入"></el-input>
              </div>

              <div class="mt20">
                <div class="label">克隆素材：</div>
                <div class="mt20">
                  <upload-audio ref="uploads"></upload-audio>
                </div>
              </div>
            </div>
            <div class="tips left">
              <div>温馨提示</div>
              <div>1、提交的录音文件时长1-3分钟，建议为安静的环境。</div>
              <div>2、录音状态自然、正常口语表达、停顿节奏自然，可以有一定情绪感情，但不要过分夸张。</div>
              <div>3、发音清晰准确，无杂音，所有录音为同一人录制，不参杂其他人声</div>
              <div>4、建议使用带话筒、性能较高的手机或收音设备录音。</div>
            </div>
            <div class="left mt15">
              <el-button type="primary" @click="debounceClick">提交审核</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="审核列表" :visible.sync="dialogVisible" width="36%">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="sound_name" label="声音名称" align="center"></el-table-column>
        <el-table-column prop="province" label="克隆素材">
          <template slot-scope="scope">
            <div class="dfc">
              <el-link v-if="scope.row.url" type="primary" @click="showAudio(scope.row.url)">素材文件</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="创建时间" width="130"></el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.status == 0">待审核</el-tag>
            <el-tag v-show="scope.row.status == 1" type="danger">审核失败</el-tag>
            <el-tag v-show="scope.row.status == 2" type="info">等待训练</el-tag>
            <el-tag v-show="scope.row.status == 3" type="warning">训练中</el-tag>
            <el-tag v-show="scope.row.status == 4" type="danger">训练失败</el-tag>
            <el-tag v-show="scope.row.status == 5" type="success">训练完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" width="150">
          <template slot-scope="scope">
            <el-popconfirm v-if="scope.row.status == 1" title="是否重新编辑素材信息？" @confirm="editItem(scope.row)">
              <span slot="reference" class="underline-text" style="color: #015de7">{{ scope.row.failure_text }}</span>
            </el-popconfirm>
            <span v-else>{{ scope.row.failure_text }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog width="30%" title="克隆素材" :visible.sync="innerVisible" :destroy-on-close="true" append-to-body>
        <div class="dfc" v-if="innerVisible">
          <audio :src="vdSrc" class="vdBox" autoplay controls></audio>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="innerVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import UploadAudio from "@/components/UploadAudio.vue";
import { sut_sound_copy, edit_sound_copy, sound_copy_list } from "@/api/audio";
export default {
  components: {
    UploadAudio,
  },
  data() {
    return {
      vdSrc: "",
      audioName: "",
      radio: 2,
      editId: null,
      timer: null,
      info: {},
      tableData: [],
      dialogVisible: false,
      innerVisible: false,
    };
  },
  mounted() {
    // 渲染视频
    this.info = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.info);
  },
  methods: {
    getList() {
      sound_copy_list().then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.dialogVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    showAudio(url) {
      this.vdSrc = url;
      this.innerVisible = true;
    },
    goBack() {
      this.$router.push({ name: "home" });
    },
    editItem(e) {
      console.log("editItem", e);
      this.audioName = e.sound_name;
      this.editId = e.id;
      this.dialogVisible = false;
    },

    debounceClick() {
      // 防抖代码
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.submit();
      }, 1200);
    },
    submit() {
      console.log(this.$refs.uploads.fileList);

      if (!this.audioName) {
        this.$message.error("请输入声音名称");
        return;
      }
      if (this.$refs.uploads.fileList.length < 1) {
        this.$message.error("请上传音频后提交克隆");
        return;
      }

      const formData = new FormData();
      formData.append("file", this.$refs.uploads.fileList[0]);
      formData.append("token", localStorage.getItem("token"));
      formData.append("sound_name", this.audioName);

      let data = "";
      if (this.editId) {
        formData.append("sound_id", this.audioName);
        data = edit_sound_copy(formData);
      } else {
        data = sut_sound_copy(formData);
      }
      data.then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log(res.data);
          this.$refs.uploads.clearFile();
          this.audioName = "";
          this.editId = null;
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  // background: #fff;
  margin: 20px 0;
  margin-bottom: 0;
  height: 88vh;
  border-radius: 15px;
  overflow: hidden;

  .top {
    width: 100%;
    height: 140px;
    background: url(../../assets/img/clongBg.png) no-repeat;
    background-size: 100% 100%;
    padding: 40px 30px;
    border-radius: 15px;
    font-size: 28px;
  }

  .info {
    text-align: left;
    margin: 30px 20px 25px;
    font-size: 16px;
    border-radius: 10px;
    padding: 10px 20px;
    width: 38vw;

    .label {
      width: 8vw;
    }

    .timeBox {
      height: 50px;
      line-height: 50px;

      .icon1 {
        font-size: 18px;
        color: #006dfe;
        margin-right: 12px;
      }
    }
  }
  .tips {
    color: #808080;
    font-size: 14px;
    background: #f3f7ff;
    padding: 10px 20px;
    border-radius: 12px;
    width: 620px;
    margin: 10px 40px 30px;
  }

  .left {
    text-align: left;
    margin-left: 30px;
  }
}

.listIcon {
  font-size: 28px;
  padding: 3px 8px 2px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  // width: 50px;
  color: #53a8ff;
  text-align: center;
}

// /deep/.el-upload {
//   border: 1px dashed #d9d9d9;
//   border-radius: 6px;
//   cursor: pointer;
//   position: relative;
//   overflow: hidden;
// }
/deep/.el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.vdBox {
  width: 315px;
  margin: 0 auto;
}
.navBox {
  background: #fff;
  padding: 10px 20px 10px;
}
</style>
